<template>
  <food-allergies-template
    :food-allergies="foodAllergies"
    :other-food-allergies="otherFoodAllergies"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import FoodAllergiesTemplate from '@/modules/questionnaire/components/steps/common/medical-background/food-allergies/FoodAllergiesTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { includes } from '@/modules/questionnaire/api/helpers';

import { OTHER_VALUE_BINDINGS } from '@/modules/questionnaire/api/constants';

const { requiredField, requiredArrayField } = fieldBuilder;

const FIELDS = [requiredArrayField('foodAllergies'), requiredField('otherFoodAllergies')];

export default {
  name: 'FoodAllergies',
  components: { FoodAllergiesTemplate },
  mixins: [makeStep(FIELDS)],
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'otherFoodAllergies') {
        return includes(this.foodAllergies, OTHER_VALUE_BINDINGS.value);
      }

      return true;
    }
  }
};
</script>
